<template>
  <div v-on-clickaway="close" class="messages-wrapper">
    <JournalMessagesButton
      v-if="unreadCount !== null"
      @click="toggle"
      :isOpen="isOpen"
      title="Уведомления"
    >
      <font-awesome-icon icon="bell" size="2x" />
      <div v-if="unreadCount > 0" class="unread-count pulse">{{ unreadCountDisplay }}</div>
    </JournalMessagesButton>
    <JournalMessagesList v-if="isOpen" url="/inbox/"/>
  </div>
</template>

<script>
import JournalMessagesButton from '@/components/JournalMessagesButton'
import JournalMessagesList from '@/components/JournalMessagesList'

import api from '@/api'
import eventBus from '@/eventBus'
import Toggleable from '@/mixins/Toggleable'

export default {
  name: 'JournalMessagesInbox',

  components: {
    JournalMessagesButton,
    JournalMessagesList
  },

  mixins: [Toggleable],

  data () {
    return {
      unreadCount: null
    }
  },

  computed: {
    unreadCountDisplay () {
      if (this.unreadCount > 9) {
        return '9+'
      }
      return this.unreadCount
    }
  },

  created () {
    this.updateUnreadCount()

    eventBus.on('receiveMessage', this.updateUnreadCount)
  },

  beforeUnmount () {
    eventBus.off('receiveMessage', this.updateUnreadCount)
  },

  methods: {
    updateUnreadCount () {
      api.get('/inbox/unread-count/')
        .then(response => {
          this.unreadCount = response.data.unread_count
        })
    },

    markAsRead () {
      api.post('/inbox/mark-as-read/')
        .then(() => {
          this.unreadCount = 0
        })
    }
  },

  watch: {
    isOpen (value) {
      if (value && this.unreadCount > 0) {
        this.markAsRead()
      }
    }
  }
}
</script>

<style scoped>
.messages-wrapper {
  position: relative;
}

.pulse {
  animation: pulse 0.6s ease infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

.unread-count {
  position: absolute;
  top: -1em;
  right: -0.8em;

  width: 2.2em;
  height: 2.2em;

  line-height: 1.6em;

  border-radius: 50%;
  border: 0.3em solid var(--shadow);

  text-align: center;
  font-size: 70%;
  font-weight: bold;

  color: white;
  background-color: var(--red);
  background-clip: padding-box;
}

@media screen and (max-width: 768px) {
  .messages-wrapper {
    position: static;
  }
}
</style>
