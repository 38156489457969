<template>
  <div class="dialog-wrapper">
    <div class="dialog-overlay" @click="close"></div>
    <form @submit.prevent="send">
      <div class="dialog-box">
        <div class="dialog-header">
          <div class="dialog-header-text">
            Написать сообщение
          </div>
          <BaseCloseButton @click="close" />
        </div>
        <div class="dialog-content">
          <div class="recipients-text-wrapper">
            <div :class="['recipients-text', {'group': isGroup}]">
              {{ recipientsText }}
            </div>
            <slot name="extra-actions"></slot>
          </div>
          <slot name="extra-content"></slot>
          <textarea
            class="text-area"
            v-model="text"
            v-focus
            :placeholder="placeholder"
            :disabled="isPending"
            spellcheck
          ></textarea>
        </div>
        <div class="dialog-buttons">
          <button
            class="button no-margin"
            :disabled="disable || isPending || !this.text.trim()"
          >Отправить</button>
          <button type="button" class="button no-background no-margin" @click="close">Отмена</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import api from '@/api'
import { getErrors } from '@/errors'
import eventBus from '@/eventBus'

export default {
  name: 'JournalPageEmployeeMessage',

  props: [
    'url',
    'recipientsText',
    'placeholder',
    'isGroup',
    'extraArguments',
    'disable'
  ],

  data () {
    return {
      text: '',
      isPending: false
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    send () {
      this.isPending = true

      api.post(this.url, {
        recipients_text: this.recipientsText,
        text: this.text,
        ...this.extraArguments
      })
        .then(response => {
          eventBus.emit('sendMessage', response.data)
          this.$toast.success('Сообщение отправлено')
          this.close()
        }, error => {
          this.$toast.error(getErrors(error).detail)
        })
        .then(() => {
          this.isPending = false
        })
    }
  }
}
</script>

<style scoped>
.dialog-box {
  width: 30em;
}

.recipients-text-wrapper {
  display: flex;
  align-items: center;
}

.recipients-text {
  padding: 0.1em 0.5em;
  border-left: 0.2em solid var(--green);
  font-weight: bold;
}

.recipients-text.group {
  border-left-color: var(--red);
}

.text-area {
  width: 100%;
  height: 10em;

  margin-top: 1.5em;
  padding: 0.7em;

  border: none;

  font-family: inherit;
  font-size: inherit;

  resize: none;
}
</style>
