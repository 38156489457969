<template>
  <div class="messages-outer">
    <div class="messages">
      <div v-for="message in messages" :key="message.id" class="message">
        <div class="message-header">
          <span :class="['message-subject', {'is-system': message.sender === null}]">
            {{ message.subject || message.recipients_text }}
          </span>
        </div>
        <div class="message-content">{{ message.text }}</div>
        <div class="message-footer">
          <div class="message-sender">{{ message.sender }}</div>
          <div
            class="message-date"
            :title="$utils.formatDateTime(message.date_created, {weekday: 'long'})"
          >
            {{ $utils.formatDate(message.date_created) }}
          </div>
        </div>
      </div>
      <InfiniteLoading @infinite="fetchMoreMessages" spinner="spiral">
        <template #no-more>Больше нет сообщений.</template>
        <template #no-results>Нет сообщений.</template>
      </InfiniteLoading>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'

import api from '@/api'
import { getErrors } from '@/errors'
import eventBus from '@/eventBus'

export default {
  name: 'JournalMessagesList',

  components: {
    InfiniteLoading
  },

  props: [
    'url',
    'insertMessageEvent'
  ],

  data () {
    return {
      messages: []
    }
  },

  created () {
    if (this.insertMessageEvent) {
      eventBus.on(this.insertMessageEvent, this.insertMessage)
    }
  },

  beforeUnmount () {
    if (this.insertMessageEvent) {
      eventBus.off(this.insertMessageEvent, this.insertMessage)
    }
  },

  methods: {
    fetchMoreMessages (state) {
      const limit = 10
      const offset = this.messages.length

      api.get(this.url, {
        params: { limit, offset }
      })
        .then(response => {
          this.messages = this.messages.concat(response.data.results)

          if (response.data.results.length > 0) {
            state.loaded()
          }
          if (response.data.results.length < limit) {
            state.complete()
          }
        }, error => {
          state.complete()
          this.$toast.error(getErrors(error).detail)
        })
    },

    insertMessage (message) {
      this.messages = [message, ...this.messages]
    }
  }
}
</script>

<style scoped>
.messages-outer {
  position: absolute;
  top: 100%;
  right: -100%;

  padding: 3em 1em;
  margin-top: 1.5em;

  width: 30em;
  height: 40em;

  background-color: var(--light-gray);

  z-index: 2;

  border-radius: 1em;
  box-shadow: 0 1px 5px var(--shadow);

  display: flex;
  flex-direction: column;
}

.messages {
  flex: 1;
  background-color: white;
  border-radius: 0.7em;
  overflow: auto;
}

.message {
  display: flex;
  flex-direction: column;

  padding: 1.5em;

  border-bottom: 0.2em solid var(--light-gray);
}

.message-header {
  font-weight: bold;
}

.message-subject {
  padding: 0.1em 0.5em;
  border-left: 0.2em solid var(--green);
}

.message-subject.is-system {
  border-left: 0.2em solid var(--red);
}

.message-content {
  margin: 1.2em 0;
  white-space: pre-line;
  line-height: 1.4;
}

.message-footer {
  display: flex;
  justify-content: space-between;
}

.message-sender {
  font-style: italic;
}

.message-date {
  margin-left: 1em;
}

@media screen and (max-width: 768px) {
  .messages-outer {
    position: absolute;

    top: 5em;
    left: 10vw;

    width: 80vw;
    height: calc(100vh - 5em);

    margin: 0;
  }
}
</style>
