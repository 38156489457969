<template>
  <JournalPageEmployee v-if="user.is_employee" :user="user"/>
  <JournalPageParent v-else-if="user.is_parent" :user="user"/>
  <JournalPageKindergartenDirector v-else-if="user.is_kindergarten_director" :user="user"/>
</template>

<script>
import JournalPageParent from '@/components/JournalPageParent'
import JournalPageEmployee from '@/components/JournalPageEmployee'
import JournalPageKindergartenDirector from '@/components/JournalPageKindergartenDirector'

export default {
  name: 'JournalPage',

  components: {
    JournalPageParent,
    JournalPageEmployee,
    JournalPageKindergartenDirector
  },

  props: [
    'user'
  ]
}
</script>
