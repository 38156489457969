import { createRouter, createWebHistory } from 'vue-router'

import IndexRoot from '@/components/IndexRoot'
import LoginPage from '@/components/LoginPage'
import JournalRoot from '@/components/JournalRoot'
import JournalPage from '@/components/JournalPage'
import JournalPageEmployeeJournal from '@/components/JournalPageEmployeeJournal'
import JournalPageEmployeeTeacherRequests from '@/components/JournalPageEmployeeTeacherRequests'
import JournalChangePassword from '@/components/JournalChangePassword'
import NotFound from '@/components/NotFound'

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: IndexRoot
    },
    {
      path: '/login',
      component: LoginPage
    },
    {
      path: '/journal',
      component: JournalRoot,
      children: [
        {
          path: '',
          component: JournalPage,
          children: [
            {
              path: '',
              component: JournalPageEmployeeJournal
            },
            {
              path: 'requests',
              component: JournalPageEmployeeTeacherRequests
            }
          ]
        },
        {
          path: 'change-password',
          component: JournalChangePassword
        }
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      component: NotFound
    }
  ]
})
