<script>
export default {
  name: 'IndexPromo',

  props: ['promo']
}
</script>

<template>
  <div
    class="promo-page"
    :style="{
      'background-image': `url(${promo.background_image})`
    }"
  >
    <div class="promo-col">
      <div v-if="promo.header_image" class="promo-header">
        <img :src="promo.header_image">
      </div>
      <div v-if="promo.video_html" class="promo-video-wrapper">
        <div v-html="promo.video_html"></div>
      </div>
      <div v-if="promo.content_html" v-html="promo.content_html" class="promo-content"></div>
    </div>
  </div>
</template>

<style>
.promo-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: 100vh;
  padding-top: 3em;

  background-attachment: fixed;
  background-size: cover;
  background-position: center;

  font-size: 120%;
}

.promo-page a {
  color: inherit;
  font-weight: bold;
}

.promo-col {
  width: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.promo-header {
  text-align: center;
  text-shadow: 0 1px 5px black;

  padding: 30px 10px;

  display: flex;
  align-items: center;
}

.promo-header img {
  max-height: 20vh;
  max-width: 100%;
}

.promo-video-wrapper {
  width: 100%;

  box-shadow: 0 0 20px black;
}

.promo-video-wrapper iframe {
  display: block;

  width: 100%;
  height: 50vh;
}

.promo-content {
  flex: 1;

  width: 100%;
  padding: 20px 0;

  font-size: 200%;
  font-family: 'Caveat', cursive;
  text-shadow: 0 0 3px black;

  color: white;
  opacity: 0.9;
}

.promo-content p {
  margin: 0.3em 0;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

@media screen and (min-width: 768px) {
  .promo-col {
    width: 768px;
  }

  .promo-video-wrapper iframe {
    height: 50vh;
  }

  .promo-content {
    width: 768px;
  }
}

@media screen and (min-width: 1200px) {
  .promo-col {
    width: 1200px;
  }

  .promo-content {
    width: 1200px;
  }
}
</style>
