<script>
import api from '@/api'
import { getErrors } from '@/errors'

export default {
  name: 'JournalPageParentViberDialog',

  data () {
    return {
      isPending: false,
      url: null
    }
  },

  created () {
    this.fetchURL()
  },

  methods: {
    close () {
      this.$emit('close')
    },

    fetchURL () {
      this.isPending = true

      api.get('/bots/viber/url/')
        .then((response) => {
          this.url = response.data.url
        }, error => {
          this.$toast.error(getErrors(error).detail)
          this.close()
        })
        .then(() => {
          this.isPending = false
        })
    }
  }
}
</script>

<template>
  <div class="dialog-wrapper">
    <div class="dialog-overlay"></div>
    <div class="dialog-box">
      <div class="dialog-header">
        <div class="dialog-header-text">Активация Viber-робота</div>
      </div>
      <template v-if="isPending">
        <div class="dialog-content" style="min-height: 5em;">
          <BaseSpinner v-if="isPending" size="50%" :withBackground="true"/>
        </div>
      </template>
      <template v-else-if="url">
        <div class="dialog-content">
          Для Вашего удобства мы запустили в работу робота-помощника.
          Пожалуйста, нажмите кнопку ниже и активируйте его 🙂👍
        </div>
        <div class="dialog-buttons">
          <a class="button no-margin viber-bot-button" :href="url" @click="close">
            <FontAwesomeIcon :icon="['fab', 'viber']" />
            Запустить Viber-робота
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped>
.viber-bot-button {
  background-color: var(--viber);
}
</style>
