<script>
export default {
  name: 'BaseCloseButton'
}
</script>

<template>
  <div class="button-close" title="Закрыть">
    <FontAwesomeIcon icon="times" size="lg" />
  </div>
</template>

<style scoped>
.button-close {
  color: var(--medium-gray);

  display: flex;
  justify-content: center;
  align-items: center;

  width: 2em;
  height: 2em;

  border-radius: 9999px;

  cursor: pointer;
}

.button-close:hover {
  color: white;
  background-color: var(--medium-gray);
}
</style>
