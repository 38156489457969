<script>
import api from '@/api'
import { getErrors } from '@/errors'

import JournalPageKindergartenDirectorKindergarten from '@/components/JournalPageKindergartenDirectorKindergarten'
import JournalPageEmployeeReport from '@/components/JournalPageEmployeeReport'

export default {
  name: 'JournalPageKindergartenDirector',

  components: {
    JournalPageKindergartenDirectorKindergarten,
    JournalPageEmployeeReport
  },

  props: ['user'],

  data () {
    return {
      kindergartens: null,
      isPending: false
    }
  },

  created () {
    this.fetchKindergartens()
  },

  methods: {
    fetchKindergartens () {
      this.isPending = true
      api.get('/kindergartens/')
        .then(response => {
          this.kindergartens = response.data
        }, error => {
          this.$toast.error(getErrors(error).detail)
        })
        .then(() => {
          this.isPending = false
        })
    }
  }
}
</script>

<template>
  <div class="journal-page">
    <BaseSpinner v-if="isPending"/>
    <template v-else-if="kindergartens && kindergartens.length > 0">
      <div class="kindergarten-list">
        <JournalPageKindergartenDirectorKindergarten
          v-for="kindergarten in kindergartens"
          :key="kindergarten.id"
          :kindergarten="kindergarten"
        />
      </div>
      <div class="controls">
        <JournalPageEmployeeReport
          :user="user"
          :activeStart="new Date(2021, 0, 1)"
        />
      </div>
    </template>
  </div>
</template>

<style scoped>
.journal-page {
  position: relative;

  flex: 1;

  display: flex;
  flex-direction: column;
}

.kindergarten-list {
  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1;
}

.controls {
  position: relative;

  margin-bottom: 2em;

  align-self: center;

  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .controls {
    flex-direction: column;
  }

  .report-button-wrapper {
    position: static;
    margin: 0;
  }
}
</style>
