<script>
import Big from 'big.js'

import api from '@/api'
import { getErrors } from '@/errors'

export default {
  name: 'JournalPageKindergartenDirectorKindergarten',

  props: ['kindergarten'],

  data () {
    return {
      operations: null,
      isPending: false
    }
  },

  created () {
    this.fetchOperations()
  },

  computed: {
    total () {
      if (!this.operations) return
      return this.operations.reduce((acc, operation) => {
        if (operation.direction === 'PAYMENT') return acc.minus(operation.amount)
        if (operation.direction === 'CHARGE') return acc.plus(operation.amount)
        return acc
      }, Big(0)).toFixed(2)
    }
  },

  methods: {
    fetchOperations () {
      this.isPending = true
      api.get('/kindergarten-operations/', {
        params: { kindergarten: this.kindergarten.id }
      })
        .then(response => {
          this.operations = response.data
        }, error => {
          this.$toast.error(getErrors(error).detail)
        })
        .then(() => {
          this.isPending = false
        })
    }
  }
}
</script>

<template>
  <div class="card">
    <h2 class="kindergarten-name">{{ kindergarten.name }}</h2>
    <div class="operations-wrapper">
      <BaseSpinner v-if="isPending" size="50%"/>
      <template v-else-if="operations">
        <table class="table">
          <tbody>
            <tr v-for="operation in operations" :key="operation.id">
              <td class="operation-date">{{ $utils.formatDate(operation.date) }}</td>
              <td>
                <FontAwesomeIcon v-if="operation.type === 'SHARE'" icon="percentage" fixed-width />
                <FontAwesomeIcon v-else-if="operation.type === 'RENT'" icon="clock" fixed-width />
                <FontAwesomeIcon v-else-if="operation.type === 'OTHER'" icon="coins" fixed-width />
                {{ operation.type_display }}
              </td>
              <td :class="['operation-amount', operation.direction.toLowerCase()]">
                {{ operation.amount }}
                <FontAwesomeIcon v-if="operation.direction === 'PAYMENT'" icon="angle-down" fixed-width />
                <FontAwesomeIcon v-else-if="operation.direction === 'CHARGE'" icon="angle-up" fixed-width />
              </td>
              <td class="operation-description">
                {{ operation.description }}
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </div>
    <div v-if="total" class="total">Баланс: {{ total }}</div>
  </div>
</template>

<style scoped>
.card {
  width: 1200px;
  margin: 1em 0;
}

.kindergarten-name {
  padding: 0.1em 0.5em;
  border-left: 0.2em solid var(--green);
}

.operations-wrapper {
  position: relative;
  min-height: 6em;
  overflow: auto;
}

.operation-date {
  text-align: right;
}

.operation-amount {
  text-align: right;
}

.operation-amount.payment {
  color: var(--red-darker);
}

.operation-amount.charge {
  color: var(--green-darker);
}

.total {
  text-align: right;
  padding: 0.5em;
  font-weight: bold;
}

@media screen and (max-width: 1200px) {
  .card {
    width: 100%;
  }

  .kindergarten-name {
    border-left: none;
    text-align: center;
  }
}
</style>
