<script>
import JournalPageEmployeeMessage from '@/components/JournalPageEmployeeMessage'

import { pluralize } from '@/utils'

export default {
  name: 'JournalPageEmployeeMessageMultiple',

  components: {
    JournalPageEmployeeMessage
  },

  props: [
    'url',
    'placeholders',
    'recipients',
    'preselected'
  ],

  data () {
    return {
      isListOpen: false,
      selected: [...this.preselected]
    }
  },

  computed: {
    noneSelected () {
      return this.selected.length === 0
    },

    oneSelected () {
      return this.selected.length === 1
    },

    allSelected () {
      return this.selected.length === this.recipients.length
    },

    recipientsText () {
      if (this.oneSelected) {
        return this.recipients.find(r => r.id === this.selected[0]).name
      }

      if (this.allSelected) {
        return 'Все'
      }

      return `${this.selected.length} ` +
        pluralize(this.selected.length, 'получатель', 'получателя', 'получателей')
    },

    placeholder () {
      const { none, one, multiple } = this.placeholders

      if (this.noneSelected && none) return none
      if (this.oneSelected && one) return one
      return multiple
    }
  },

  methods: {
    toggleList () {
      this.isListOpen = !this.isListOpen
    },

    toggleSelectAll () {
      if (this.allSelected) {
        this.deselectAll()
      } else {
        this.selectAll()
      }
    },

    selectAll () {
      this.selected = this.recipients.map(r => r.id)
    },

    deselectAll () {
      this.selected = []
    }
  }
}
</script>

<template>
  <JournalPageEmployeeMessage
    @close="$emit('close')"
    :url="url"
    :recipientsText="recipientsText"
    :placeholder="placeholder"
    :isGroup="!oneSelected"
    :extraArguments="{recipients: selected}"
    :disable="noneSelected"
  >
    <template #extra-actions>
      <div
        @click="toggleList"
        :class="['add-button', {'active': isListOpen}]"
        title="Добавить получателей"
      >
        <FontAwesomeIcon icon="plus" />
      </div>
    </template>
    <template #extra-content v-if="isListOpen">
      <div class="recipients">
        <div>
          <label>
            <input
              @change="toggleSelectAll"
              :checked="allSelected"
              type="checkbox"
            >
            <strong>Все</strong>
          </label>
        </div>
        <div v-for="recipient in recipients" :key="recipient.id">
          <label>
            <input
              v-model="selected"
              :value="recipient.id"
              type="checkbox"
            >
            {{ recipient.name }}
          </label>
        </div>
      </div>
    </template>
  </JournalPageEmployeeMessage>
</template>

<style scoped>
.add-button {
  width: 1.5em;
  height: 1.5em;

  color: var(--medium-gray);

  border-radius: 50%;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.add-button:hover, .add-button.active {
  color: white;
  background-color: var(--green);
}

.recipients {
  margin-top: 1.5em;

  max-height: 20vh;

  overflow: auto;
}
</style>
