<script>
import api from '@/api'
import { getErrors } from '@/errors'
import { dateToISO, pluralize } from '@/utils'

import JournalPageEmployeeDropdown from '@/components/JournalPageEmployeeDropdown'

export default {
  name: 'JournalPageEmployeeReport',

  components: {
    JournalPageEmployeeDropdown
  },

  props: [
    'user',
    'activeStart',
    'activeEnd'
  ],

  data () {
    const now = new Date()
    const dateStart = new Date(
      now.getFullYear(),
      now.getMonth(),
      1
    )
    const dateEnd = new Date(
      dateStart.getFullYear(),
      dateStart.getMonth() + 1,
      0
    )

    return {
      reportName: '',
      isPending: false,
      dateRange: { dateStart, dateEnd }
    }
  },

  computed: {
    reports () {
      return [
        {
          name: 'main',
          title: 'Общий',
          onSubmit: () => { this.download('/reports/main/') },
          visible: this.user.is_employee
        },
        {
          name: 'kindergarten',
          title: 'По детским садам',
          onSubmit: () => { this.download('/reports/kindergarten/') },
          visible: 'kindergartens.generate_kindergarten_report' in this.user.permissions
        },
        {
          name: 'concerts',
          title: 'По концертам',
          onSubmit: () => { this.download('/reports/concerts/') },
          visible: this.user.is_employee
        },
        {
          name: 'director',
          title: 'Для заведующих',
          onSubmit: () => { this.download('/reports/director/') },
          visible: this.user.is_kindergarten_director || this.user.is_admin
        },
        {
          name: 'director-email',
          title: 'Для заведующих (рассылка)',
          onSubmit: () => { this.send('/reports/director-email/') },
          visible: 'kindergartens.send_director_reports' in this.user.permissions,
          buttonText: 'Отправить рассылку'
        }
      ].filter(report => report.visible)
    },

    report () {
      return this.reports.find(report => report.name === this.reportName)
    }
  },

  methods: {
    post (url) {
      this.isPending = true

      const promise = api.post(url, {
        date_start: dateToISO(this.dateRange.dateStart),
        date_end: dateToISO(this.dateRange.dateEnd)
      })

      promise
        .catch(error => {
          this.$toast.error(getErrors(error).detail)
        })
        .then(() => {
          this.isPending = false
        })

      return promise
    },

    download (url) {
      this.post(url).then(response => {
        window.location = response.data.url
      })
    },

    send (url) {
      this.post(url).then(response => {
        const { count } = response.data
        if (count === 0) {
          this.$toast.error('Не найдено ни одного получателя')
        } else {
          this.$toast.success(`Успешно ${pluralize(count, 'отправлен', 'отправлено', 'отправлено')} ${count} ${pluralize(count, 'отчёт', 'отчёта', 'отчётов')}`)
        }
      })
    }
  },

  watch: {
    reports: {
      handler (reports) {
        this.reportName = reports[0] ? reports[0].name : ''
      },
      immediate: true
    }
  }
}
</script>

<template>
  <JournalPageEmployeeDropdown v-if="reports.length > 0">
    <template #button>
      <font-awesome-icon
        icon="file-excel"
        class="report-button"
        size="2x"
        title="Создать отчёт"
      />
    </template>
    <select
      v-if="reports.length > 1"
      v-model="reportName"
      class="report-select"
    >
      <option disabled value="">Выберите отчёт</option>
      <option
        v-for="report in reports"
        :key="report.name"
        :value="report.name"
      >
        {{ report.title }}
      </option>
    </select>
    <div v-if="report" class="report-form">
      <div class="small">
        <BaseDateRangeSelector
          v-model:range="dateRange"
          :activeStart="activeStart"
          :activeEnd="activeEnd"
        />
      </div>
      <button
        @click="report.onSubmit"
        :disabled="isPending"
        class="button report-create-button"
      >
        {{ isPending ? 'Ожидайте...' : (report.buttonText || 'Создать отчёт') }}
      </button>
    </div>
  </JournalPageEmployeeDropdown>
</template>

<style scoped>
.small {
  font-size: 0.7em;
}

.report-button {
  color: #ddd;
}

.report-select {
  margin-bottom: 1em;
  padding: 0.5em;
}

.report-form {
  display: flex;
  flex-direction: column;
}

.report-create-button {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
</style>
