<script>
import axios from 'axios'

import BaseWeek from '@/components/BaseWeek'
import JournalPageEmployeeGroupAttendance from '@/components/JournalPageEmployeeGroupAttendance'
import JournalPageEmployeeGroupTeacherHour from '@/components/JournalPageEmployeeGroupTeacherHour'

import api from '@/api'
import eventBus from '@/eventBus'
import { getErrors } from '@/errors'
import { keyToCmp } from '@/utils'

const mapMarks = (marks, dates, people, getPerson) => {
  const grouped = {}

  for (const mark of marks) {
    const person = getPerson(mark)
    const date = mark.date

    if (!grouped[person]) {
      grouped[person] = {}
    }
    grouped[person][date] = mark
  }

  const mapped = {}

  for (const person of people) {
    mapped[person.id] = {}

    for (const date of dates) {
      mapped[person.id][date] = grouped[person.id] && grouped[person.id][date]
    }
  }

  return mapped
}

export default {
  name: 'JournalPageEmployeeGroupDetail',

  components: {
    BaseWeek,
    JournalPageEmployeeGroupAttendance,
    JournalPageEmployeeGroupTeacherHour
  },

  props: [
    'group',
    'children',
    'teachers'
  ],

  data () {
    return {
      attendances: null,
      teacherHours: null,
      isPending: false,

      dates: null,
      rangeFrom: null,
      rangeTo: null,

      contentHeight: null
    }
  },

  mounted () {
    this.contentHeight = `${this.$el.clientHeight}px`

    const childToShowEl = this.$el.querySelector('.to-show')

    if (childToShowEl) {
      childToShowEl.scrollIntoView({ block: 'center' })
    } else if (this.groupToShow === this.group.id) {
      this.$el.scrollIntoView()
    }
  },

  computed: {
    groupToShow () {
      return Number(this.$route.query.group)
    },

    childToShow () {
      return Number(this.$route.query.child)
    },

    childrenSorted () {
      if (!this.children) return null

      return Array.from(this.children).sort(keyToCmp(
        child => child.attendances_left === 0 && !child.has_expected_payment
      ))
    },

    kindergartenGroups () {
      if (!this.childrenSorted) return null

      const result = []
      const grouped = {}

      for (const child of this.childrenSorted) {
        const groupID = child.kindergarten_group && child.kindergarten_group.id

        if (!grouped[groupID]) {
          grouped[groupID] = []
          result.push({
            ...child.kindergarten_group,
            children: grouped[groupID]
          })
        }

        grouped[groupID].push(child)
      }

      return result.sort(keyToCmp(group => group.name))
    },

    childrenMarked () {
      return this.dates && this.attendances && this.dates
        .map(date =>
          this.children
            .map(child => this.attendances[child.id][date])
            .filter(attendance => !!attendance)
            .length
        )
    }
  },

  created () {
    eventBus.on('confirm', this.fetchMarks)
  },

  beforeUnmount () {
    eventBus.off('confirm', this.fetchMarks)
  },

  methods: {
    fetchMarks () {
      this.isPending = true

      axios.all([
        api.get('/journal/attendances/', {
          params: {
            child__group: this.group.id,
            date__gte: this.rangeFrom,
            date__lte: this.rangeTo
          }
        }),
        api.get('/journal/teacher-hours/', {
          params: {
            group: this.group.id,
            date__gte: this.rangeFrom,
            date__lte: this.rangeTo
          }
        })
      ])
        .then(([attendances, teacherHours]) => {
          this.attendances = mapMarks(attendances.data, this.dates, this.children, mark => mark.child)
          this.teacherHours = mapMarks(teacherHours.data, this.dates, this.teachers, mark => mark.teacher)
        }, error => {
          this.$toast.error(getErrors(error).detail)
        })
        .then(() => {
          this.isPending = false
        })
    },

    changeRange ({ dates, rangeFrom, rangeTo }) {
      this.dates = dates
      this.rangeFrom = rangeFrom
      this.rangeTo = rangeTo

      this.fetchMarks()
    }
  }
}
</script>

<template>
  <div class="detail">
    <div v-if="isPending" class="spinner-wrapper">
      <BaseSpinner size="50%" :withBackground="true"/>
    </div>
    <div
      @click.prevent="$emit('startGroupMessage', group)"
      class="badge badge-group"
      :title="group.name"
    >
      <h2 class="badge-text">{{ group.name }}</h2>
    </div>
    <div class="week-wrapper">
      <BaseWeek
        @changeRange="changeRange"
        :contentHeight="contentHeight"
      />
    </div>
    <div v-if="children && teachers" class="journal">
      <div class="journal-section">
        <div
          v-for="kindergartenGroup in kindergartenGroups"
          :key="kindergartenGroup.id"
          class="subgroup"
        >
          <div v-if="kindergartenGroup.id" class="subgroup-header">
            <div class="subgroup-header-label truncate">{{ kindergartenGroup.name }}</div>
          </div>
          <div
            v-for="child in kindergartenGroup.children"
            :key="child.id"
            :class="['journal-line', {'to-show shake': child.id === childToShow}]"
          >
            <div
              @click.prevent="$emit('showChildInfo', child)"
              :class="[
                'badge',
                'badge-person',
                {warning: child.attendances_left === 1},
                {error: child.attendances_left === 0 && !child.has_expected_payment}
              ]"
              :title="`${child.last_name} ${child.first_name} ${child.middle_name}`"
            >
              <div class="badge-text truncate">
                <strong>{{ child.last_name }}</strong>
                {{ child.first_name }}
              </div>
            </div>
            <div v-if="attendances && dates" class="marks">
              <JournalPageEmployeeGroupAttendance
                v-for="(date, i) in dates"
                :key="i"
                :child="child"
                :date="date"
                :group="group"
                v-model:attendance="attendances[child.id][date]"
              />
            </div>
          </div>
        </div>
        <div v-if="!isPending && childrenMarked" class="mark-count-line">
          <div v-for="(count, i) in childrenMarked" :key="i">
            <div v-if="count > 0" class="mark-count">
              <strong>{{ count }}</strong>/{{ children.length }}
            </div>
          </div>
        </div>
      </div>
      <div class="journal-section">
        <div v-if="teachers.length > 0" class="subgroup">
          <div class="subgroup-header">
            <div class="subgroup-header-label teachers truncate">Преподаватели</div>
          </div>
          <div
            v-for="teacher in teachers"
            :key="teacher.id"
            class="journal-line"
          >
            <div
              @click.prevent="$emit('startTeacherMessage', teacher)"
              class="badge badge-person"
              :title="`${teacher.last_name} ${teacher.first_name} ${teacher.middle_name}`"
            >
              <div class="badge-text truncate">
                <strong>{{ teacher.last_name }}</strong>
                {{ teacher.first_name }}
              </div>
            </div>
            <div v-if="teacherHours && dates" class="marks">
              <JournalPageEmployeeGroupTeacherHour
                v-for="(date, i) in dates"
                :key="i"
                :teacher="teacher"
                :date="date"
                :group="group"
                v-model:teacherHour="teacherHours[teacher.id][date]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.detail {
  position: relative;

  display: grid;
  grid-template-columns: var(--left-pane-width) 1fr;
  grid-template-areas:
    "badge   week"
    "journal journal"
  ;

  background: linear-gradient(to right,
    var(--red),
    var(--red) var(--left-pane-width),
    white var(--left-pane-width),
    white
  );
}

.spinner-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;

  z-index: 1;

  grid-column-start: 2;
}

.badge {
  padding: 0 1.6em;

  color: white;

  display: flex;
  justify-content: space-between;
  align-items: center;

  text-shadow: 0px 1px 1px var(--shadow);

  cursor: pointer;

  background-color: rgba(255, 255, 255, 0);
}

.badge:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.badge.badge-group {
  grid-area: badge;

  height: 6em;

  background-color: var(--light-overlay);
}

.badge.badge-group .badge-text {
  border-left: 0.2em solid var(--green);
  padding-left: 0.8em;
  text-align: left;

  max-height: 100%;
  overflow: hidden;
}

.badge.badge-person.warning .badge-text {
  color: #ff8;
}

.badge.badge-person.error .badge-text {
  opacity: 0.6;
  font-style: italic;
}

.journal {
  grid-area: journal;

  width: 100%;
}

.journal-section {
  counter-reset: person;

  margin-bottom: 2em;
}

.subgroup-header {
  display: flex;

  padding: 1em 0 1em 2em;
}

.subgroup-header-label {
  max-width: 30%;

  padding: 0.2em 1em;

  color: white;
  background-color: var(--green);

  border-radius: 9999px;
}

.subgroup-header-label.teachers {
  color: var(--red);
  background-color: white;

  font-weight: bold;
}

.journal-line {
  display: flex;

  height: 2em;
}

.journal-line:nth-child(even) {
  background-color: var(--light-overlay);
}

.badge.badge-person {
  width: var(--left-pane-width);

  font-weight: 400;
}

.badge.badge-person .badge-text {
  flex: 1;
}

.badge.badge-person::before {
  counter-increment: person;
  content: counter(person);

  width: 1.5em;
}

.marks {
  flex: 1;

  padding: 0 3em;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.mark-count-line {
  height: 3.5em;

  padding: 0 3em;
  margin-left: var(--left-pane-width);
  margin-bottom: -3.5em;

  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
}

.mark-count {
  text-align: center;
  font-weight: 400;

  color: var(--medium-gray);
}

.mark-count strong {
  color: var(--green);
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.message-button {
  color: white;
  opacity: 0.5;
  height: 1em;
  margin-left: 0.5em;
}

.badge:hover .message-button {
  opacity: 1;
}

.week-wrapper {
  height: 6em;

  background-color: var(--light-overlay);
}
</style>
