<script setup>
import { defineProps, defineEmits, computed, ref, watch } from 'vue'
import { useToast } from 'vue-toastification'

import JournalPageParentConcertReservationDialogSeat from '@/components/JournalPageParentConcertReservationDialogSeat'

import api from '@/api'
import { getErrors } from '@/errors'

const step = 100
const scale = 0.4

const toast = useToast()

const props = defineProps(['concertID', 'seats'])
const emit = defineEmits(['createReservation', 'destroyReservation'])

const rowLabels = computed(() => {
  const grouped = {}
  const groupedArray = []

  for (const seat of props.seats) {
    const key = JSON.stringify([seat.sector_name, seat.row])
    if (!grouped[key]) {
      grouped[key] = []
      groupedArray.push({
        sector: seat.sector_name,
        row: seat.row,
        seats: grouped[key]
      })
    }
    grouped[key].push(seat)
  }

  const labels = []

  for (const { row, seats } of groupedArray) {
    const leftmostSeat = seats.reduce((curr, seat) => seat.x < (curr ? curr.x : Infinity) ? seat : curr, null)
    const rightmostSeat = seats.reduce((curr, seat) => seat.x > (curr ? curr.x : -Infinity) ? seat : curr, null)

    labels.push({
      row,
      style: {
        transform: `translate(-100%, -50%) translate(${leftmostSeat.x - step / 2}px, ${leftmostSeat.y}px)`,
        'text-align': 'right'
      }
    })
    labels.push({
      row,
      style: {
        transform: `translate(0, -50%) translate(${rightmostSeat.x + step / 2}px, ${rightmostSeat.y}px)`,
        'text-align': 'left'
      }
    })
  }

  return labels
})

const hallRect = computed(() => {
  const padding = {
    top: 0,
    right: 300,
    bottom: 150,
    left: 300
  }

  const left = props.seats.reduce((curr, seat) => Math.min(curr, seat.x), Infinity) - step / 2 - padding.left
  const right = props.seats.reduce((curr, seat) => Math.max(curr, seat.x), -Infinity) + step / 2 + padding.right

  const top = props.seats.reduce((curr, seat) => Math.min(curr, seat.y), Infinity) - step / 2 - padding.top
  const bottom = props.seats.reduce((curr, seat) => Math.max(curr, seat.y), -Infinity) + step / 2 + padding.bottom

  return {
    left,
    right,
    top,
    bottom,
    width: right - left,
    height: bottom - top
  }
})

const hoveredSeat = ref()
const tooltipSeat = ref()

let hoveredSeatTimeoutID

watch(hoveredSeat, (_hoveredSeat) => {
  if (_hoveredSeat) {
    tooltipSeat.value = _hoveredSeat
  }

  clearTimeout(hoveredSeatTimeoutID)

  hoveredSeatTimeoutID = setTimeout(() => {
    tooltipSeat.value = null
  }, _hoveredSeat ? 3000 : 500)
})

const pendingSeats = ref([])
const addPendingSeat = (uuid) => { pendingSeats.value.push(uuid) }
const removePendingSeat = (uuid) => { pendingSeats.value = pendingSeats.value.filter((i) => i !== uuid) }
const isSeatPending = (uuid) => pendingSeats.value.includes(uuid)

const createReservation = (seatUUID) =>
  api.post('/reservations/', {
    concert: props.concertID,
    seat: seatUUID
  })

const destroyReservation = (reservationID) =>
  api.delete(`/reservations/${reservationID}/`)

const onSeatClick = (seat) => {
  if (isSeatPending(seat.uuid)) return

  if (!seat.is_available) {
    toast.error('Выбранное место недоступно к продаже')
    return
  }

  addPendingSeat(seat.uuid)

  let promise

  if (!seat.reservation) {
    promise = createReservation(seat.uuid)
      .then((response) => {
        emit('createReservation', response.data)
      }, (error) => {
        toast.error(getErrors(error).detail)
      })
  } else {
    promise = destroyReservation(seat.reservation.id)
      .then(() => {
        emit('destroyReservation', seat.reservation.id)
      }, (error) => {
        toast.error(getErrors(error).detail)
      })
  }

  promise.then(() => {
    removePendingSeat(seat.uuid)
  })
}
</script>

<template>
  <transition name="fade">
    <div v-if="tooltipSeat && tooltipSeat.is_available" class="seat-tooltip">
      <div>{{ tooltipSeat.sector_name }}</div>
      <div>Ряд <strong>{{ tooltipSeat.row }}</strong> Место <strong>{{ tooltipSeat.number }}</strong></div>
      <div>Цена <strong>{{ $utils.formatDecimal(tooltipSeat.price) }}</strong> ₽</div>
    </div>
  </transition>
  <div class="hall-wrapper">
    <div class="stage-wrapper">
      <div class="stage">
        <div class="stage-label">
          Сцена
        </div>
      </div>
    </div>
    <div
      class="hall"
      :style="{
        width: `${hallRect.width * scale}px`,
        height: `${hallRect.height * scale}px`
      }"
    >
      <div
        class="hall-data"
        :style="{
          transform: `scale(${scale}) translate(${-hallRect.left}px, ${-hallRect.top}px)`
        }"
      >
        <div class="seats">
          <JournalPageParentConcertReservationDialogSeat
            v-for="seat in props.seats"
            :key="seat.uuid"
            :seat="seat"
            :isPending="isSeatPending(seat.uuid)"
            @click="onSeatClick(seat)"
            @mouseenter="hoveredSeat = seat"
            @mouseleave="hoveredSeat = null"
          />
        </div>
        <div class="row-labels">
          <div
            v-for="rowLabel in rowLabels"
            :key="rowLabel.row"
            :style="rowLabel.style"
            class="row-label"
          >
            Ряд {{ rowLabel.row }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.hall-wrapper {
  width: max-content;
  user-select: none;
}

.hall {
  overflow: hidden;
}

.stage-wrapper {
  width: 100%;
  padding: 4em;
}

.stage {
  border-radius: 1em;
  padding: 2em;

  background-color: var(--light-gray);

  display: flex;
  justify-content: center;
  align-items: center;
}

.stage-label {
  position: sticky;
  right: 20px;
  left: 20px;

  font-size: 200%;
  font-weight: bold;

  text-shadow: 0 1px 0 white;
}

.hall-data {
  position: relative;
  z-index: 1;

  width: 0;
  height: 0;
}

.seat-tooltip {
  position: fixed;

  z-index: 2;

  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.9);

  margin: 1em;
  padding: 1em;
}

.row-label {
  position: absolute;

  font-size: 35px;
  font-weight: bold;
  white-space: nowrap;

  padding: 0 40px;
}
</style>
