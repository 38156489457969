<script>
import JournalPageParentConcertReservationDialog from '@/components/JournalPageParentConcertReservationDialog'
import JournalPageParentConcertRefundGuide from '@/components/JournalPageParentConcertRefundGuide'

import api from '@/api'
import { getErrors } from '@/errors'

export default {
  name: 'JournalPageParentConcert',

  components: {
    JournalPageParentConcertReservationDialog,
    JournalPageParentConcertRefundGuide
  },

  props: ['concert', 'children'],

  data () {
    return {
      isReservationDialogVisible: false,
      isPrintPending: false
    }
  },

  methods: {
    showReservationDialog () {
      this.isReservationDialogVisible = true
    },

    hideReservationDialog () {
      this.isReservationDialogVisible = false
    },

    onPurchase () {
      this.hideReservationDialog()
      this.$toast.success('Билеты успешно куплены')
      this.$emit('purchaseTicket')
    },

    printTickets () {
      if (this.isPrintPending) return

      this.isPrintPending = true

      api.post('/tickets/pdf/')
        .then((response) => {
          window.location = response.data.url
        }, (error) => {
          this.$toast.error(getErrors(error).detail)
        })
    }
  }
}
</script>

<template>
  <div class="card">
    <JournalPageParentConcertReservationDialog
      v-if="isReservationDialogVisible"
      @purchase="onPurchase"
      @close="hideReservationDialog"
      :concert="concert"
      :children="children"
    />
    <div class="card-content">
      <div class="header">
        <div>
          <h2 class="header-text">
            {{ concert.name }}
          </h2>
          <div class="header-subtext">
            {{ $utils.formatDateTime(concert.date, {weekday: 'long'}) }}
          </div>
        </div>
        <div class="buy-ticket">
          <button @click="showReservationDialog" class="button green no-margin buy-ticket-button">
            Купить билеты
          </button>
        </div>
      </div>
      <div v-if="concert.tickets.length > 0" class="detail">
        <div class="detail-columns">
          <div style="flex: 1;">
            <h3 class="detail-header">
              {{ concert.tickets.length > 1 ? 'Купленные билеты' : 'Купленный билет' }}
            </h3>
            <ul class="ticket-list">
              <li v-for="ticket in concert.tickets" :key="ticket.id" class="ticket-list-item">
                <FontAwesomeIcon icon="ticket-alt" />
                {{ ticket.seat.sector_name }} /
                Ряд <strong>{{ ticket.seat.row }}</strong> Место <strong>{{ ticket.seat.number }}</strong>
              </li>
            </ul>
          </div>
          <div style="align-self: center;">
            <button @click="printTickets" :disabled="isPrintPending" class="button green no-margin">
              <FontAwesomeIcon v-if="isPrintPending" icon="cog" spin />
              <FontAwesomeIcon v-else icon="print" />
              {{ concert.tickets.length > 1 ? 'Распечатать билеты' : 'Распечатать билет' }}
            </button>
          </div>
        </div>
        <JournalPageParentConcertRefundGuide />
      </div>
    </div>
  </div>
</template>

<style scoped>
.card {
  width: 768px;

  margin: 1em 0;
  padding: 1em;

  border-radius: 1em;

  background-color: var(--medium-overlay);
}

.card-content {
  position: relative;
  z-index: 0;

  border-radius: 0.5em;
  overflow: hidden;
}

.header {
  padding: 2em;

  color: white;
  background-color: var(--green);
  background-image: linear-gradient(60deg, transparent, transparent 50%, rgba(0, 0, 0, 0.1) 80%, rgba(0, 0, 0, 0.1));

  text-shadow: 0px 1px 1px var(--shadow);

  display: flex;
  justify-content: space-between;
  align-items: center;

  box-shadow: 0 0 10px var(--shadow);
}

.header-text {
  margin: 0;

  font-size: 2em;
}

.header-subtext {
  margin-top: 0.3em;
}

.subheader-text {
  font-size: 50%;
}

.buy-ticket {
  text-align: center;
}

.buy-ticket-button {
  box-shadow: 0 1px 5px var(--shadow);
}

.detail {
  padding: 2em;

  background-color: white;
  background-image: linear-gradient(to top, transparent, transparent 50%, rgba(0, 0, 0, 0.05) 80%, rgba(0, 0, 0, 0.05));
  background-size: auto 200px;
  background-repeat: no-repeat;
}

.detail-columns {
  display: flex;
}

.detail-header {
  margin-top: 0;
}

.ticket-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ticket-list-item {
  padding: 0.3em 0;
}

@media screen and (max-width: 768px) {
  .card {
    width: 100%;

    padding: 0;
    margin: 0;

    border-radius: 0;
  }

  .card-content {
    border-radius: 0;
  }
}
</style>
