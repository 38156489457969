<script>
import IndexPageTermsOfService from '@/components/IndexPageTermsOfService'

import api from '@/api'
import { getErrors } from '@/errors'
import { dateToISO } from '@/utils'

export default {
  name: 'IndexPageRegisterForm',

  components: {
    IndexPageTermsOfService
  },

  data () {
    return {
      isSent: false,
      isPending: false,
      errors: {},

      fields: [
        { name: 'child_name', label: 'ФИО ребёнка', value: '' },
        { name: 'child_date_of_birth', label: 'Дата рождения ребёнка', value: '', type: 'date', convert: dateToISO },
        { name: 'child_kindergarten', label: 'Какой сад или школу посещает ребёнок', value: '' },
        { name: 'child_kindergarten_group', label: 'Название группы сада или класса в школе', value: '' },
        { name: 'parent_name', label: 'ФИО родителя', value: '' },
        { name: 'parent_phone_number', label: 'Телефон родителя для связи', value: '' }
      ],

      hasAcceptedTOS: false,
      isTOSVisible: false
    }
  },

  computed: {
    formParams () {
      const reducer = (acc, field) => ({
        ...acc,
        [field.name]: field.convert
          ? field.convert(field.value)
          : field.value
      })
      return this.fields
        .filter((field) => !!field.value)
        .reduce(reducer, {})
    }
  },

  methods: {
    submit () {
      if (this.isPending) return
      if (!this.hasAcceptedTOS) return

      this.errors = {}
      this.isPending = true

      api.post('/guests-registration-requests/', this.formParams)
        .then(
          (response) => {
            this.isSent = true
          },
          (error) => {
            this.errors = getErrors(error)
          })
        .then(() => {
          this.isPending = false
          this.$el.parentNode.scrollIntoView({ block: 'start' })
        })
    }
  }
}
</script>

<template>
  <form v-if="!isSent" class="form register-form" @submit.prevent="submit">
    <div v-for="field in fields" :key="field.name">
      <div class="register-form-group">
        <label :for="field.name" class="register-form-label">
          {{ field.label }}
        </label>
        <div class="register-form-input-wrapper">
          <BaseDatepicker
            v-if="field.type === 'date'"
            v-model:date="field.value"
            v-slot="{ open, isOpen }"
          >
            <input
              :id="field.name"
              :class="['form-input', 'register-form-input', {'has-errors': !!errors[field.name]}]"
              @focus="open"
              :value="$utils.formatDate(field.value)"
              :readonly="isOpen"
              required
            />
          </BaseDatepicker>
          <input
            v-else
            :id="field.name"
            :class="['form-input', 'register-form-input', {'has-errors': !!errors[field.name]}]"
            v-model="field.value"
            required
          >
        </div>
      </div>
      <BaseFormError :errors="errors[field.name]" class="register-form-alert" />
    </div>
    <div class="register-form-group">
      <label class="register-form-checkbox-label">
        <input type="checkbox" v-model="hasAcceptedTOS" required>
        Я принимаю условия
        <a class="register-form-link" @click.prevent="isTOSVisible = true">
          <span>Политики конфиденциальности</span>
        </a>
        и даю своё согласие на обработку персональных данных
      </label>
    </div>
    <BaseFormError :errors="errors.non_field_errors" />
    <BaseFormError :errors="errors.detail" />
    <div class="register-form-submit-row">
      <button
        class="button green register-form-submit-button"
        :disabled="isPending"
      >
        Отправить заявку
      </button>
    </div>
    <div class="tos-modal-wrapper">
      <IndexPageTermsOfService
        v-if="isTOSVisible"
        @close="isTOSVisible = false"
      />
    </div>
  </form>
  <div v-else class="register-success">
    <div>Спасибо, мы получили вашу заявку 😊</div>
    <div>Ожидайте звонка администратора.</div>
  </div>
</template>

<style scoped>
.register-form {
  font-size: 1.2em;
}

.register-form-group {
  display: flex;
  align-items: center;
}

.register-form-label {
  width: 30%;
  padding-right: 1em;
  font-weight: 700;
  text-align: right;
}

.register-form-input-wrapper {
  flex: 1;
}

.register-form-input {
  color: var(--dark-text);
  width: 100%;
  padding: 0.8em 1em;
  text-align: left;
}

.register-form-checkbox-label {
  margin: 0.5em 0 0.5em 30%;
}

.register-form-link {
  color: inherit;
  text-decoration: underline dotted;
  cursor: pointer;
}

.register-form-submit-row {
  align-self: flex-end;
}

.register-form-alert {
  margin: 0 0 0.5em 30% !important;
}

.has-errors {
  border-style: dashed;
}

.tos-modal-wrapper {
  color: var(--dark-text);
}

.register-success {
  font-size: 200%;
  font-weight: 700;
  text-align: center;
  margin: 0.5em 0;
  line-height: 1.5em;
}

@media screen and (max-width: 768px) {
  .register-form-group {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 1em;
  }

  .register-form-label {
    width: 100%;
    text-align: left;
  }

  .register-form-checkbox-label {
    margin: 0;
  }

  .register-form-alert {
    margin: 0 0 1em 0 !important;
  }
}
</style>
