<template>
  <div
    class="attendance-wrapper"
    @click="toggle"
    :title="tooltip"
  >
    <div
      :class="[
        'attendance',
        {'marked': isMarked},
        {'confirmed': isConfirmed},
        {'pending': isPending},
        {'unpaid': isUnpaid}
      ]"
    ></div>
  </div>
</template>

<script>
import api from '@/api'
import { getErrors } from '@/errors'

export default {
  name: 'JournalPageEmployeeGroupAttendance',

  props: [
    'child',
    'date',
    'group',
    'attendance'
  ],

  data () {
    return {
      isPending: false
    }
  },

  computed: {
    isMarked () {
      return !!this.attendance
    },

    isConfirmed () {
      return this.attendance && this.attendance.receipt
    },

    isUnpaid () {
      return this.attendance && this.attendance.is_unpaid
    },

    tooltip () {
      return this.attendance && `Отметил: ${this.attendance.created_by}`
    }
  },

  methods: {
    showErrors (error) {
      const errors = getErrors(error)
      this.$toast.error(
        errors.detail ||
        (errors.non_field_errors && errors.non_field_errors.join(' '))
      )
    },

    toggle () {
      if (this.isPending) {
        return
      }

      this.isPending = true

      let promise

      if (this.attendance) {
        promise = api.delete(`/journal/attendances/${this.attendance.id}/`)
          .then(() => {
            this.$emit('update:attendance', null)
          }, this.showErrors)
      } else {
        promise = api.post('/journal/attendances/', {
          child: this.child.id,
          group: this.group.id,
          date: this.date
        })
          .then(response => {
            this.$emit('update:attendance', response.data)
          }, this.showErrors)
      }

      promise
        .then(() => {
          this.isPending = false
        })
    }
  }
}
</script>

<style scoped>
.attendance-wrapper {
  width: 2em;
  height: 2em;

  padding: 0.4em;

  cursor: pointer;

  position: relative;
}

.attendance {
  width: 100%;
  height: 100%;

  border-radius: 40%;

  background-color: var(--light-overlay);
}

.attendance.pending {
  background-color: var(--light-gray);
  filter: brightness(0.7);
}

.attendance.marked {
  border: 0.2em solid var(--green);
  background-color: var(--green-lightest);
}

.attendance.marked.unpaid {
  border: 0.2em solid var(--red);
  background-color: var(--red-lightest);
}

.attendance.marked.confirmed {
  background-color: var(--green);
}
</style>
