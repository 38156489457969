<template>
  <div class="index-root">
    <div :class="['navigation', {'hidden': !isNavigationVisible && !promo}, {'transparent': !!promo}]">
      <div class="logo" @click="closePromo">
        <img src="../static/images/logo_white.png" alt="АкваЛэнд">
      </div>
      <div class="navigation-buttons">
        <button v-if="promo" @click="closePromo" class="button close-promo">
          <font-awesome-icon icon="times" fixedWidth /> закрыть
        </button>
        <router-link v-else class="button" to="/journal">личный кабинет</router-link>
      </div>
    </div>
    <BaseSpinner v-if="isPending" />
    <IndexPromo v-else-if="promo" :promo="promo" />
    <IndexPage v-else-if="texts" class="root-content" :texts="texts" />
  </div>
</template>

<script>
import axios from 'axios'

import IndexPage from '@/components/IndexPage'
import IndexPromo from '@/components/IndexPromo'

import { getErrors } from '@/errors'

export default {
  name: 'IndexRoot',

  components: {
    IndexPage,
    IndexPromo
  },

  data () {
    return {
      isNavigationVisible: true,

      texts: null,
      promos: null,
      isPending: false,

      promoIndex: 0
    }
  },

  mounted () {
    this.lastScrollY = window.scrollY
    window.addEventListener('scroll', this.onWindowScroll)
  },

  beforeUnmount () {
    window.removeEventListener('scroll', this.onWindowScroll)
  },

  created () {
    this.fetchData()
  },

  computed: {
    promo () {
      if (!this.promos) return
      return this.promos[this.promoIndex]
    }
  },

  methods: {
    fetchData () {
      this.isPending = true

      axios.all([
        axios.get('/api/landing/'),
        axios.get('/api/landing-promos/')
      ])
        .then(([texts, promos]) => {
          this.texts = texts.data
          this.promos = promos.data
        }, error => {
          this.$toast.error(getErrors(error).detail)
        })
        .then(() => {
          this.isPending = false
        })
    },

    onWindowScroll () {
      this.isNavigationVisible = window.scrollY < this.lastScrollY
      this.lastScrollY = window.scrollY
    },

    closePromo () {
      if (this.promo) {
        this.promoIndex++
      }
    }
  }
}
</script>

<style scoped>
.index-root {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

.root-content {
  flex: 1;

  display: flex;
  flex-direction: column;
}

.navigation {
  position: fixed;
  z-index: 3;
  top: 0;

  display: flex;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 3em;

  padding: 0 1em 0 1em;

  background-color: var(--primary-medium);

  transition: top 0.3s;
}

.navigation.hidden {
  top: -3em;
}

.navigation.transparent {
  background: none;
}

.navigation .logo {
  height: 100%;

  padding: 0.65em 0 0.3em 0.5em;
}

.navigation .logo img {
  height: 100%;
}

.button.close-promo {

}
</style>
