<template>
  <div
    ref="spinner"
    :class="[
      'spinner',
      {'with-overlay': withOverlay},
      {'with-background': withBackground}
    ]"
    :style="{
      'font-size': size
    }"
  ></div>
</template>

<script>
export default {
  name: 'BaseSpinner',

  props: {
    size: {
      type: String,
      default: '100%'
    },
    color: {
      type: [String, Object],
      default: null
    },
    withOverlay: {
      type: Boolean,
      default: true
    },
    withBackground: {
      type: Boolean,
      default: false
    }
  },

  mounted () {
    this.$watch(() => this.color, (color) => {
      if (color) {
        this.$refs.spinner.style.setProperty('--spinner-color', color)
      }
    }, { immediate: true })
  }
}
</script>

<style scoped>
.spinner {
  --spinner-color: var(--green);
}

.spinner.with-overlay {
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner.with-background {
  background-color: var(--white-overlay);
}

.spinner::after {
  content: " ";
  display: block;

  width: 5em;
  height: 5em;

  border: 0.5em solid var(--spinner-color);
  border-radius: 50%;
  border-bottom-color: transparent;
  border-left-color: transparent;

  animation: spinner 1s linear infinite;
}

.spinner.white::after {
  border-color: white;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
