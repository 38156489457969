<template>
  <div class="journal-root">
    <BaseNavigation>
      <template v-if="user">
        <JournalMessagesInbox v-if="user.is_teacher || user.is_parent"/>
        <JournalMessagesOutbox v-if="user.is_employee"/>
        <JournalUserMenu :user="user"/>
      </template>
    </BaseNavigation>
    <div class="content">
      <BaseSpinner v-if="isPending"/>
      <router-view
        v-else-if="user"
        :user="user"
      ></router-view>
    </div>
  </div>
</template>

<script>
import JournalMessagesInbox from '@/components/JournalMessagesInbox'
import JournalMessagesOutbox from '@/components/JournalMessagesOutbox'
import JournalUserMenu from '@/components/JournalUserMenu'

import api from '@/api'
import { getToken, removeToken } from '@/authToken'
import { getErrors } from '@/errors'

export default {
  name: 'JournalRoot',

  components: {
    JournalMessagesInbox,
    JournalMessagesOutbox,
    JournalUserMenu
  },

  data () {
    return {
      isPending: false,
      user: null
    }
  },

  beforeRouteEnter (to, from, next) {
    if (getToken()) {
      next()
    } else {
      next('/login')
    }
  },

  created () {
    this.fetchUser()
  },

  methods: {
    fetchUser () {
      this.isPending = true

      api.get('/me/')
        .then(response => {
          this.user = response.data
        }, error => {
          if (error.response && error.response.status === 401) {
            removeToken()
            this.$router.push('/login')
          } else {
            this.$toast.error(getErrors(error).detail)
          }
        })
        .then(() => {
          this.isPending = false
        })
    }
  }
}
</script>

<style scoped>
.journal-root {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

.content {
  position: relative;

  flex: 1;

  display: flex;
  flex-direction: column;
}
</style>
