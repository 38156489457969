<script>
import api from '@/api'
import { getErrors } from '@/errors'
import eventBus from '@/eventBus'

export default {
  name: 'JournalPageParentChildBuySubscriptionDialog',

  props: ['child'],

  data () {
    return {
      tariffs: null,
      tariffsPending: false,
      buyPending: false,
      errors: {},
      selectedTariff: null,
      autoRenew: true
    }
  },

  created () {
    this.fetchTariffs()
  },

  methods: {
    fetchTariffs () {
      this.tariffsPending = true

      api.get('/subscription-tariffs/', {
        params: { child: this.child.id }
      })
        .then(response => {
          this.tariffs = response.data
        }, error => {
          this.$toast.error(getErrors(error).detail)
        })
        .then(() => {
          this.tariffsPending = false
        })
    },

    buySelectedTariff () {
      this.errors = {}
      this.buyPending = true

      api.post('/subscriptions/', {
        child: this.child.id,
        tariff: this.selectedTariff.id,
        auto_renew: this.autoRenew
      })
        .then(response => {
          this.$toast.success(`Абонемент "${response.data.name}" успешно активирован`)
          eventBus.emit('buySubscription')
          this.close()
        }, error => {
          this.errors = getErrors(error)
        })
        .then(() => {
          this.buyPending = false
        })
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>

<template>
  <div class="dialog-wrapper">
    <div class="dialog-overlay" @click="close"></div>
    <BaseSpinner v-if="tariffsPending" />
    <div class="dialog-box" v-else>
      <div class="dialog-header">
        <div class="dialog-header-text">Купить абонемент</div>
        <BaseCloseButton @click="close" />
      </div>
      <template v-if="tariffs && tariffs.length === 0">
        <div class="dialog-content">
          Извините, в данный момент абонементы недоступны для покупки.
        </div>
        <div class="dialog-buttons">
          <button class="button green no-margin" @click="close">OK</button>
        </div>
      </template>
      <template v-else>
        <div class="dialog-content">
          <div v-if="tariffs && tariffs.length > 0">
            <div
              :class="['tariff-card', {'selected': selectedTariff && selectedTariff.id === tariff.id}]"
              v-for="tariff in tariffs"
              :key="tariff.id"
              @click="selectedTariff = tariff"
            >
              <div class="tariff-header">
                {{ tariff.name }}
              </div>
              <div class="tariff-body">
                <div class="tariff-price">
                  {{ tariff.price }} ₽
                </div>
              </div>
            </div>
            <template v-if="selectedTariff">
              <BaseAlert v-if="selectedTariff.date_end" color="warning">
                Абонемент "{{ selectedTariff.name }}" будет действовать по {{ $utils.formatDate(selectedTariff.date_end) }}
              </BaseAlert>
              <div class="auto-renew">
                <label>
                  <input type="checkbox" v-model="autoRenew">
                  <strong>Автоматическое продление:</strong>
                  после исчерпания текущего абонемента будет автоматически активирован новый
                </label>
              </div>
            </template>
          </div>
          <BaseAlert v-if="errors.non_field_errors">
            <div v-for="(error, i) in errors.non_field_errors" :key="i">
              {{ error }}
            </div>
          </BaseAlert>
          <BaseAlert v-if="errors.detail">{{ errors.detail }}</BaseAlert>
        </div>
        <div class="dialog-buttons">
          <button class="button no-margin" @click="buySelectedTariff" :disabled="!selectedTariff || buyPending">Купить абонемент</button>
          <button class="button no-background no-margin" @click="close">Отмена</button>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped>
.dialog-box {
  width: 40em;
}

.tariff-card {
  background-color: white;

  margin: 0.5em 0;

  border-radius: 1em;
  box-shadow: 0 1px 5px var(--shadow);

  text-align: center;

  overflow: hidden;

  cursor: pointer;

  display: flex;
}

.tariff-card:hover {
  box-shadow: 0 3px 10px var(--shadow);
}

.tariff-card.selected {
  color: white;
  background-color: var(--green);
}

.tariff-header {
  flex: 4;

  font-weight: bold;

  padding: 1em;
}

.tariff-body {
  flex: 3;

  padding: 1em;

  display: flex;
  justify-content: space-between;
}

.tariff-card.selected .tariff-body {
  font-weight: bold;
}

.auto-renew {
  padding-top: 1em;
}
</style>
