<template>
  <div class="journal-page">
    <JournalPagePushNotifications :user="user" />
    <div v-if="user.is_teacher" class="tab-links">
      <router-link to="/journal" class="tab-link" exact-active-class="active">
        Журнал посещений
      </router-link>
      <router-link to="/journal/requests" class="tab-link" exact-active-class="active">
        Внесение в базу
      </router-link>
    </div>
    <router-view :user="user"/>
  </div>
</template>

<script>
import JournalPagePushNotifications from '@/components/JournalPagePushNotifications'

export default {
  name: 'JournalPageEmployee',

  components: {
    JournalPagePushNotifications
  },

  props: [
    'user'
  ]
}
</script>

<style scoped>
.journal-page {
  position: relative;

  flex: 1;

  display: flex;
  flex-direction: column;
}
</style>
