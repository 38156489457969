<script>
import Toggleable from '@/mixins/Toggleable'

export default {
  name: 'JournalPageEmployeeDropdown',

  mixins: [Toggleable]
}
</script>

<template>
  <div v-on-clickaway="close" class="dropdown-wrapper">
    <div @click="toggle" class="dropdown-button">
      <slot name="button"></slot>
    </div>
    <div v-show="isOpen" class="dropdown-dialog">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
.dropdown-wrapper {
  position: relative;

  height: 2em;
}

.dropdown-button {
  height: 100%;

  cursor: pointer;
}

.dropdown-dialog {
  position: absolute;
  bottom: 3em;
  left: -8.3em;

  padding: 1em;

  width: 18em;

  background-color: var(--light-gray);

  z-index: 2;

  border-radius: 1em;
  box-shadow: 0 1px 5px var(--shadow);

  display: flex;
  flex-direction: column;
}
</style>
