<template>
  <div v-on-clickaway="close" class="user-menu-wrapper">
    <div @click.prevent="toggle" class="user-menu-button">
      <div class="user-name">{{ user.name }}</div>
      <font-awesome-icon
        icon="angle-down"
        :class="['arrow', {'rotate-180': isOpen}]"
      />
    </div>
    <div v-if="isOpen" class="user-menu" @click="close">
      <router-link to="/journal/change-password" class="user-menu-item">
        Сменить пароль
      </router-link>
      <a v-if="user.is_employee" href="/admin" class="user-menu-item">
        Администрирование
      </a>
      <div @click="logOut" class="user-menu-item dangerous">
        Выйти
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import { removeToken } from '@/authToken'
import { hasSupport as hasPushSupport, getSubscription } from '@/pushNotifications'
import Toggleable from '@/mixins/Toggleable'

export default {
  name: 'JournalUserMenu',

  mixins: [Toggleable],

  props: [
    'user'
  ],

  methods: {
    async logOut () {
      if (hasPushSupport()) {
        const pushSubscription = await getSubscription()

        if (pushSubscription) {
          try {
            await pushSubscription.unsubscribe()
          } catch (e) {
            this.$toast.error('Произошла ошибка. Попробуйте ещё раз.')
            return
          }
        }
      }

      if (this.user.is_employee) {
        axios.get('/admin/logout/')
      }

      removeToken()
      this.$toast.success('До встречи!')
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.user-menu-wrapper {
  position: relative;
}

.user-menu-button {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 12em;
  height: 2em;
  padding: 0 1em;
  border-radius: 1em;

  color: white;
  font-weight: bold;

  background-color: var(--green);

  cursor: pointer;

  z-index: 3;

  text-shadow: 0 -1px 1px var(--shadow);
}

.user-name {
  flex: 1;

  margin: 0 1em;
  text-align: center;
}

.arrow {
  color: var(--white-overlay);
  font-size: 1.5em;
  transition: transform 0.2s;
}

.rotate-180 {
  transform: rotate(180deg);
}

.user-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  padding: 2em 0.5em 0.5em 0.5em;
  border-radius: 1em;

  background-color: var(--green);

  z-index: 2;
}

.user-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 2em;
  padding: 0.5em 1em;
  border-radius: 1em;

  color: white;
  text-decoration: none;
  font-size: 0.9em;

  cursor: pointer;
}

.user-menu-item:hover {
  background-color: var(--medium-overlay);
}

.user-menu-item.dangerous {
  background-color: var(--red);
}

.user-menu-item.dangerous:hover {
  background-color: var(--red-darker);
}
</style>
