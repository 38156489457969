<script>
import axios from 'axios'

import { getErrors } from '@/errors'

export default {
  name: 'IndexPageTermsOfService',

  data () {
    return {
      isPending: false,
      texts: null
    }
  },

  created () {
    this.fetchTexts()
  },

  methods: {
    fetchTexts () {
      this.isPending = true

      axios.get('/api/terms-of-service/')
        .then(response => {
          this.texts = response.data
        }, error => {
          this.$toast.error(getErrors(error).detail)
          this.close()
        })
        .then(() => {
          this.isPending = false
        })
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>

<template>
  <div class="dialog-wrapper">
    <div class="dialog-overlay" @click="close"></div>
    <div class="dialog-box tos-dialog-box">
      <div class="dialog-header">
        <div class="dialog-header-text">Правила предоставления услуг</div>
        <BaseCloseButton @click="close" />
      </div>
      <div class="dialog-content tos-dialog-content">
        <BaseSpinner v-if="isPending" />
        <div class="tos-text" v-else-if="texts">
          <h2 class="tos-text-header">Политика конфиденциальности</h2>
          <div v-html="texts.privacy_policy"></div>
          <h2 class="tos-text-header">Согласие на обработку персональных данных</h2>
          <div v-html="texts.personal_data_processing_policy"></div>
        </div>
      </div>
      <div class="dialog-buttons">
        <button class="button green" type="button" @click="close">Закрыть</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.tos-dialog-box {
  width: 80vw;
  height: 80vh;

  display: flex;
  flex-direction: column;
}

.tos-dialog-content {
  flex: 1;
  overflow-y: auto;
}

.tos-text {
  flex: 1;
  overflow-y: auto;
  font-size: 12pt;
}

.tos-text-header {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .dialog-box {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}
</style>
