<script>
import api from '@/api'
import { getErrors } from '@/errors'

export default {
  name: 'JournalPageParentMerchOrderDialog',

  props: ['item', 'children'],

  data () {
    return {
      selectedChildID: this.children[0].id,
      count: 1,
      isPending: false,
      errors: {}
    }
  },

  computed: {
    total () {
      return this.count * this.item.price
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    submit () {
      this.isPending = true
      this.errors = {}

      api.post('/merch/orders/', {
        item: this.item.id,
        child: this.selectedChildID,
        count: this.count
      })
        .then(response => {
          this.$emit('purchase', response.data)
        }, error => {
          this.errors = getErrors(error)
        })
        .then(() => {
          this.isPending = false
        })
    }
  }
}
</script>

<template>
  <div class="dialog-wrapper">
    <div class="dialog-overlay" @click="close"></div>
    <div class="dialog-box">
      <div class="dialog-header">
        <div class="dialog-header-text">Купить</div>
        <BaseCloseButton @click="close" />
      </div>
      <form @submit.prevent="submit">
        <div class="dialog-content">
          <BaseSpinner v-if="isPending" size="50%" :withBackground="true"/>
          <div class="form-field">
            <div class="form-field-label">Товар</div>
            <div class="form-field-input">{{ item.name }} ({{ item.short_description }})</div>
          </div>
          <div class="form-field">
            <div class="form-field-label">Кол-во</div>
            <div class="form-field-input">
              <input v-model="count" type="number" min="1" required>
            </div>
          </div>
          <div class="form-field" v-if="count > 0">
            <div class="form-field-label">Сумма</div>
            <div class="form-field-input">{{ total }} ₽</div>
          </div>
          <div class="form-field" v-if="children.length > 1">
            <div class="form-field-label">Счёт</div>
            <div class="form-field-input">
              <div class="child-list">
                <div v-for="child in children" :key="child.id">
                  <label>
                    <input type="radio" :value="child.id" v-model="selectedChildID">
                    {{ child.first_name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <BaseAlert v-if="errors.non_field_errors">
            <div v-for="(error, i) in errors.non_field_errors" :key="i">
              {{ error }}
            </div>
          </BaseAlert>
          <BaseAlert v-if="errors.detail">{{ errors.detail }}</BaseAlert>
        </div>
        <div class="dialog-buttons">
          <button type="submit" class="button no-margin" :disabled="count === 0 || isPending">Купить</button>
          <button type="button" class="button no-background no-margin" @click="close">Отмена</button>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>
.form-field {
  display: flex;
  margin: 0.3em 0;
}

.form-field-label {
  flex: 1;
  margin-right: 1em;
  font-weight: bold;
  text-align: right;
}

.form-field-input {
  width: 60%;
}
</style>
