<script>
export default {
  name: 'JournalPageEmployeeChildInfo',

  props: ['child'],

  methods: {
    close () {
      this.$emit('close')
    },

    startChildMessage () {
      this.$emit('startChildMessage', this.child)
      this.close()
    }
  }
}
</script>

<template>
  <div class="dialog-wrapper">
    <div class="dialog-overlay" @click="close"></div>
    <div class="dialog-box">
      <div class="dialog-header">
        <div class="dialog-header-text">
          {{ child.last_name }} {{ child.first_name }}
        </div>
        <BaseCloseButton @click="close" />
      </div>
      <div class="dialog-content">
        <table class="child-info" style="margin-bottom: 1em;">
          <tbody>
            <tr>
              <td class="child-info-label">Родитель</td>
              <td class="child-info-value">{{ child.parent_full_name }}</td>
            </tr>
            <tr>
              <td class="child-info-label">Номер телефона</td>
              <td class="child-info-value">
                <a class="phone-number-link" :href="`tel:${ child.parent_phone_number }`">
                  {{ $utils.formatPhoneNumber(child.parent_phone_number) }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="child-info">
          <tbody>
            <tr>
              <td class="child-info-label">Баланс</td>
              <td class="child-info-value">{{ $utils.formatDecimal(child.balance) }} ₽</td>
            </tr>
            <tr>
              <td class="child-info-label">Занятий осталось</td>
              <td class="child-info-value">
                <template v-if="child.attendances_left < 0">∞</template>
                <template v-else>
                  {{ child.attendances_left }}
                  <template v-if="child.attendances_left === 1">
                    <FontAwesomeIcon icon="exclamation-triangle" style="color: orange;" />
                  </template>
                  <template v-else-if="child.attendances_left === 0">
                    <FontAwesomeIcon icon="exclamation-triangle" style="color: var(--red);" />
                  </template>
                </template>
              </td>
            </tr>
            <tr>
              <td class="child-info-label">Неоплаченных</td>
              <td class="child-info-value">
                {{ child.unpaid_attendance_count }}
                <template v-if="child.unpaid_attendance_count > 0">
                  <FontAwesomeIcon icon="exclamation-triangle" style="color: var(--red);" />
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="dialog-buttons">
        <button type="button" class="button green no-margin" @click="startChildMessage">
          <font-awesome-icon icon="envelope" class="message-button" />
          Написать сообщение
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-text {
  padding: 0.5em 1em;

  border-radius: 9999px;

  color: white;
  font-weight: bold;

  align-self: flex-start;

  background-color: var(--green);
}

.child-info {
  width: 100%;
}

.child-info-label {
  font-weight: bold;
  text-align: right;
  vertical-align: top;
  padding-right: 1em;
  width: 40%;
}

.phone-number-link {
  color: var(--dark-gray);
}
</style>
