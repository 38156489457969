<template>
  <div v-on-clickaway="close" class="messages-wrapper">
    <JournalMessagesButton
      @click="toggle"
      :isOpen="isOpen"
      title="Отправленные сообщения"
    >
      <font-awesome-icon icon="envelope" size="2x" />
    </JournalMessagesButton>
    <keep-alive>
      <JournalMessagesList
        v-if="isOpen"
        url="/outbox/"
        insertMessageEvent="sendMessage"
      />
    </keep-alive>
  </div>
</template>

<script>
import JournalMessagesButton from '@/components/JournalMessagesButton'
import JournalMessagesList from '@/components/JournalMessagesList'

import Toggleable from '@/mixins/Toggleable'

export default {
  name: 'JournalMessagesOutbox',

  components: {
    JournalMessagesButton,
    JournalMessagesList
  },

  mixins: [Toggleable]
}
</script>

<style scoped>
.messages-wrapper {
  position: relative;
}

@media screen and (max-width: 768px) {
  .messages-wrapper {
    position: static;
  }
}
</style>
