<script>
import { hasSupport, isDenied, getSubscription, subscribe, registerSubscription } from '@/pushNotifications'

const State = {
  idle: 'idle',

  asking: 'asking',
  waitingForPermission: 'waitingForPermission',
  subscribing: 'subscribing',
  registering: 'registering',

  declined: 'declined',
  denied: 'denied',
  subscriptionFailed: 'subscriptionFailed',
  registrationFailed: 'registrationFailed',

  success: 'success'
}

export default {
  name: 'JournalPagePushNotifications',

  props: ['user'],

  data () {
    return {
      state: State.idle,
      subscription: null
    }
  },

  async created () {
    this.State = State

    if (this.user.is_admin) return
    if (!hasSupport() || isDenied() || this.hasDeclined()) return

    this.subscription = await getSubscription()

    if (!this.subscription) {
      this.ask()
    } else if (this.isNotRegistered()) {
      this.register()
    }
  },

  computed: {
    hasDeclinedKey () {
      return `push_declined_${this.user.id}`
    },

    isNotRegisteredKey () {
      return `push_not_registered_${this.user.id}`
    }
  },

  methods: {
    hasDeclined () {
      return localStorage.getItem(this.hasDeclinedKey) === 'true'
    },

    setHasDeclined () {
      localStorage.setItem(this.hasDeclinedKey, 'true')
    },

    isNotRegistered () {
      return localStorage.getItem(this.isNotRegisteredKey) === 'true'
    },

    setIsNotRegistered () {
      localStorage.setItem(this.isNotRegisteredKey, 'true')
    },

    unsetIsNotRegistered () {
      localStorage.removeItem(this.isNotRegisteredKey)
    },

    ask () {
      this.state = State.asking
    },

    async requestPermission () {
      this.state = State.waitingForPermission

      const permission = await Notification.requestPermission()
      if (permission === 'granted') {
        this.subscribe()
      } else {
        this.deny()
      }
    },

    async subscribe () {
      this.state = State.subscribing

      try {
        this.subscription = await subscribe()
      } catch (e) {
        this.failSubscription()
        throw e
      }

      this.register()
    },

    async register () {
      this.state = State.registering

      try {
        await registerSubscription(this.subscription)
      } catch (e) {
        if (!(e.response && e.response.status === 400)) {
          this.failRegistration()
          return
        }
      }

      this.complete()
    },

    decline () {
      this.state = State.declined
      this.setHasDeclined()
    },

    deny () {
      this.state = State.denied
    },

    failSubscription () {
      this.state = State.subscriptionFailed
    },

    failRegistration () {
      this.state = State.registrationFailed
      this.setIsNotRegistered()
    },

    complete () {
      this.state = State.success
      this.unsetIsNotRegistered()
    },

    end () {
      this.state = State.idle
    }
  }
}
</script>

<template>
  <div class="container">
    <template v-if="state === State.asking">
      <div class="icon">
        <FontAwesomeLayers class="fa-3x">
          <FontAwesomeIcon icon="circle" />
          <FontAwesomeIcon icon="bell" transform="shrink-8" style="color: white" />
        </FontAwesomeLayers>
      </div>
      <div class="content">
        <p v-if="user.is_employee">
          Вы можете получать push-уведомления с сообщениями от администраторов.
        </p>
        <p v-else-if="user.is_parent">
          Вы можете получать push-уведомления о поступлениях платежей,
          низком балансе и&nbsp;сообщениях от преподавателей.
        </p>
        <p><strong>Включить push-уведомления на данном устройстве?</strong></p>
        <div class="buttons">
          <button class="button" @click="requestPermission">Включить</button>
          <button class="button no-background" @click="decline">Нет, спасибо</button>
        </div>
      </div>
    </template>
    <template v-if="state === State.waitingForPermission">
      <div class="icon">
        <FontAwesomeIcon icon="info-circle" size="3x" />
      </div>
      <div class="content">
        <p><strong>Пожалуйста, разрешите получение уведомлений в появившемся диалоге.</strong></p>
      </div>
    </template>
    <template v-if="state === State.subscribing">
      <div class="icon">
        <FontAwesomeIcon icon="cog" size="3x" spin />
      </div>
      <div class="content">
        <p>Получение подписки...</p>
      </div>
    </template>
    <template v-if="state === State.registering">
      <div class="icon">
        <FontAwesomeIcon icon="cog" size="3x" spin />
      </div>
      <div class="content">
        <p>Регистрация подписки...</p>
      </div>
    </template>
    <template v-if="state === State.declined">
      <div class="icon">
        <FontAwesomeLayers class="fa-3x">
          <FontAwesomeIcon icon="bell" transform="shrink-8" />
          <FontAwesomeIcon icon="ban" transform="shrink-10 right-5 up-5" style="color: var(--red)" />
        </FontAwesomeLayers>
      </div>
      <div class="content">
        <p><strong>Вы отказались от получения push-уведомлений.</strong></p>
        <button class="button no-background no-padding" @click="end">OK</button>
      </div>
    </template>
    <template v-if="state === State.denied">
      <div class="icon">
        <FontAwesomeLayers class="fa-3x">
          <FontAwesomeIcon icon="comment-alt" transform="shrink-8" />
          <FontAwesomeIcon icon="ban" style="color: var(--red)" />
        </FontAwesomeLayers>
      </div>
      <div class="content">
        <p><strong>Всплывающие уведомления запрещены в браузере.</strong></p>
        <p>Разрешите отображение уведомлений в настройках браузера для данного веб-сайта.</p>
        <button class="button no-background no-padding" @click="end">OK</button>
      </div>
    </template>
    <template v-if="state === State.subscriptionFailed">
      <div class="icon">
        <FontAwesomeIcon icon="exclamation-circle" size="3x" />
      </div>
      <div class="content">
        <p><strong>Ошибка при получении подписки.</strong></p>
        <button class="button" @click="subscribe">Повторить попытку</button>
      </div>
    </template>
    <template v-if="state === State.registrationFailed">
      <div class="icon">
        <FontAwesomeIcon icon="exclamation-circle" size="3x" />
      </div>
      <div class="content">
        <p><strong>Ошибка при регистрации подписки.</strong></p>
        <button class="button" @click="register">Повторить попытку</button>
      </div>
    </template>
    <template v-if="state === State.success">
      <div class="icon">
        <FontAwesomeLayers class="fa-3x">
          <FontAwesomeIcon icon="bell" transform="shrink-8" />
          <FontAwesomeIcon icon="check-circle" transform="shrink-10 right-5 up-5" style="color: var(--green)" />
        </FontAwesomeLayers>
      </div>
      <div class="content">
        <p><strong>Теперь вы подписаны на push-уведомления</strong></p>
        <button class="button no-background no-padding" @click="end">OK</button>
      </div>
    </template>
  </div>
</template>

<style scoped>
.container {
  align-self: center;

  width: 768px;
  padding: 0 3em;

  display: flex;
}

.icon {
  width: 3em;
  height: 3em;
  margin: 1em 1.5em 1em 0;
}

.content {
  flex: 1;
}

.buttons {
  display: flex;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
  }
}
</style>
