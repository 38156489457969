<script>
import axios from 'axios'
import Big from 'big.js'
import chroma from 'chroma-js'

import JournalPageParentConcertReservationDialogHall from '@/components/JournalPageParentConcertReservationDialogHall'
import JournalPageParentConcertTicketDialog from '@/components/JournalPageParentConcertTicketDialog'

import api from '@/api'
import { getErrors } from '@/errors'

const mapReservations = (reservations) => {
  const result = {}

  for (const reservation of reservations) {
    result[reservation.seat] = reservation
  }

  return result
}

const prepareSeats = (seats, reservations) => {
  const reservationsMapped = mapReservations(reservations)

  const availableSeats = seats.filter((seat) => !!seat.price)

  const minPrice = availableSeats.reduce((curr, seat) => Math.min(curr, seat.price), Infinity)
  const maxPrice = availableSeats.reduce((curr, seat) => Math.max(curr, seat.price), 0)

  const getColorFromPrice = (price) => {
    const t = (minPrice === maxPrice)
      ? 1
      : (price - minPrice) / (maxPrice - minPrice)

    return chroma.hcl(t * 180 + 10, 65, 73)
  }

  return seats.map((seat) => ({
    ...seat,
    is_available: seat.is_available || !!reservationsMapped[seat.uuid],
    color: getColorFromPrice(seat.price)
  }))
}

export default {
  name: 'JournalPageParentConcertReservationDialog',

  components: {
    JournalPageParentConcertReservationDialogHall,
    JournalPageParentConcertTicketDialog
  },

  props: ['concert', 'children'],

  emits: ['purchase', 'close'],

  data () {
    return {
      seats: null,
      reservations: null,
      isPending: false,

      isTicketDialogVisible: false
    }
  },

  computed: {
    reservationCount () {
      if (!this.reservations) return 0

      return this.reservations.length
    },

    reservationTotal () {
      if (!this.reservations) return 0

      return this.reservations.reduce((acc, reservation) => acc.plus(reservation.price), Big(0))
    },

    seatsWithReservations () {
      if (!this.seats || !this.reservations) return null

      const reservationsMapped = mapReservations(this.reservations)

      return this.seats.map((seat) => ({
        ...seat,
        reservation: reservationsMapped[seat.uuid] || null
      }))
    }
  },

  created () {
    this.fetchData()
  },

  methods: {
    close () {
      this.$emit('close')
    },

    showTicketDialog () {
      this.isTicketDialogVisible = true
    },

    hideTicketDialog () {
      this.isTicketDialogVisible = false
    },

    fetchData () {
      this.isPending = true

      axios.all([
        api.get(`/concerts/${this.concert.id}/seats/`),
        api.get(`/reservations/?concert=${this.concert.id}`)
      ])
        .then(
          ([seats, reservations]) => {
            this.reservations = reservations.data
            this.seats = prepareSeats(seats.data, reservations.data)
          },
          (error) => {
            this.$toast.error(getErrors(error).detail)
          })
        .then(() => {
          this.isPending = false
        })
    },

    addReservation (reservation) {
      this.reservations.push(reservation)
    },

    removeReservation (reservationID) {
      this.reservations = this.reservations.filter((reservation) => reservation.id !== reservationID)
    }
  }
}
</script>

<template>
  <div class="dialog-wrapper">
    <div class="dialog-overlay"></div>
    <div class="dialog-box wide">
      <div class="dialog-header">
        <div class="dialog-header-text">Выбор мест</div>
        <BaseCloseButton @click="close" />
      </div>
      <div class="dialog-content reservation-dialog-content">
        <BaseSpinner v-if="isPending"/>
        <JournalPageParentConcertReservationDialogHall
          v-else-if="seatsWithReservations"
          :concertID="concert.id"
          :seats="seatsWithReservations"
          @createReservation="addReservation"
          @destroyReservation="removeReservation"
        ></JournalPageParentConcertReservationDialogHall>
      </div>
      <div class="reservation-dialog-footer">
        <div>
          <div v-if="reservations" class="reservation-total">
            <div>
              В корзине:&nbsp;
            </div>
            <div>
              <strong>
                {{ reservationCount }} {{ $utils.pluralize(reservationCount, 'место', 'места', 'мест') }}
              </strong>
              на сумму <strong>{{ reservationTotal }} ₽</strong>
            </div>
          </div>
        </div>
        <div>
          <button
            class="button green no-margin"
            @click="showTicketDialog"
            :disabled="reservationCount === 0"
          >
            Перейти к оплате
          </button>
        </div>
      </div>
    </div>
  </div>
  <JournalPageParentConcertTicketDialog
    v-if="isTicketDialogVisible"
    @purchase="$emit('purchase')"
    @close="hideTicketDialog"
    :concert="concert"
    :reservations="reservations"
    :children="children"
  />
</template>

<style scoped>
.reservation-dialog-content {
  height: 70vh;
  padding: 0;
  overflow: auto;
}

.reservation-dialog-footer {
  background-color: var(--light-gray);
  border-radius: 0 0 1em 1em;

  padding: 1.5em;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reservation-total {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .reservation-total {
    flex-direction: row;
  }
}
</style>
