<template>
  <button
    @click="confirm"
    :disabled="isPending"
    class="button"
  >
    Подтвердить
  </button>
</template>

<script>
import api from '@/api'
import eventBus from '@/eventBus'
import { getErrors } from '@/errors'
import { pluralize } from '@/utils'

export default {
  name: 'JournalPageAdminConfirm',

  data () {
    return {
      isPending: false
    }
  },

  methods: {
    confirm () {
      this.isPending = true

      api.post('/journal/confirm/')
        .then(response => {
          const { attendances, teacher_hours: hours } = response.data
          if (attendances || hours) {
            this.$toast.success(
              'Успешно подтверждено ' +
              `${attendances} ${pluralize(attendances, 'посещение', 'посещения', 'посещений')} и ` +
              `${hours} ${pluralize(hours, 'тренеро-час', 'тренеро-часа', 'тренеро-часов')}.`
            )
            eventBus.emit('confirm')
          } else {
            this.$toast.success('Нет неподтверждённых записей')
          }
        }, error => {
          this.$toast.error(getErrors(error).detail)
        })
        .then(() => {
          this.isPending = false
        })
    }
  }
}
</script>
