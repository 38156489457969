<script>
export default {
  name: 'JournalPageParentChildPayDialog',

  inject: ['texts'],

  props: [
    'child'
  ],

  computed: {
    guide () {
      return this.texts.payment_guide
        .replace(/{ *child_id *}/g, this.child.id)
    }
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<template>
  <div class="dialog-wrapper">
    <div class="dialog-overlay" @click="close"></div>
    <div class="dialog-box">
      <div class="dialog-header">
        <div class="dialog-header-text">Инструкция по оплате</div>
        <BaseCloseButton @click="close" />
      </div>
      <div class="dialog-content guide-text" v-html="guide"></div>
      <div class="dialog-buttons">
        <button class="button green no-margin" @click="close">OK</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.dialog-box {
  width: 30em;
}

.guide-text {
  line-height: 1.4em;
  font-weight: normal;
}

.guide-text:deep(ol) {
  padding-left: 1em;
}
</style>
